import React from 'react';
import { useModalDeCorrecoes } from '../../../ModalDeCorrecoesContext';

/**
 * @param Filtros Aqui estão os filtros possíveis para as criticas (inconformidades) anotadas ao validar os itens do XML
 */

export const Filtros: React.FC = () => {
  const {
    filtroOrigem,
    setFiltroOrigem,
    filtroStatus,
    setFiltroStatus,
    handleFiltrosOrigem,
    handleFiltrosStatus,
  } = useModalDeCorrecoes();

  return (
    <div className="row">
      <div className="col-6" style={{ margin: '10px 0px 10px 0px' }}>
        <label htmlFor="todos">Origem</label>
        <div className="botoes-de-filtro">
          <button
            id="todos-origem"
            type="button"
            onClick={() => {
              setFiltroOrigem('TODOS');
              handleFiltrosOrigem('TODOS');
            }}
            className={filtroOrigem === 'TODOS' ? 'botao-de-filtro-ativo' : ''}
          >
            TODOS
          </button>
          <button
            type="button"
            onClick={() => {
              setFiltroOrigem('MIX');
              handleFiltrosOrigem('MIX');
            }}
            className={filtroOrigem === 'MIX' ? 'botao-de-filtro-ativo' : ''}
          >
            MIX
          </button>
          <button
            type="button"
            onClick={() => {
              setFiltroOrigem('CAD');
              handleFiltrosOrigem('CAD');
            }}
            className={filtroOrigem === 'CAD' ? 'botao-de-filtro-ativo' : ''}
          >
            CADASTRO
          </button>
          <button
            type="button"
            onClick={() => {
              setFiltroOrigem('GTIN');
              handleFiltrosOrigem('GTIN');
            }}
            className={filtroOrigem === 'GTIN' ? 'botao-de-filtro-ativo' : ''}
          >
            GTIN
          </button>
          <button
            type="button"
            onClick={() => {
              setFiltroOrigem('TRIB');
              handleFiltrosOrigem('TRIB');
            }}
            className={filtroOrigem === 'TRIB' ? 'botao-de-filtro-ativo' : ''}
          >
            TRIBUTAÇÃO
          </button>
        </div>
      </div>
      <div
        className="col-6 flex-column"
        style={{ margin: '10px 0px 10px 0px' }}
      >
        <label htmlFor="todos">Status</label>
        <div className="botoes-de-filtro">
          <button
            id="todos-status"
            type="button"
            onClick={() => {
              setFiltroStatus('TODOS');
              handleFiltrosStatus('TODOS');
            }}
            className={filtroStatus === 'TODOS' ? 'botao-de-filtro-ativo' : ''}
          >
            TODOS
          </button>
          <button
            type="button"
            onClick={() => {
              setFiltroStatus('INF');
              handleFiltrosStatus('INF');
            }}
            className={filtroStatus === 'INF' ? 'botao-de-filtro-ativo' : ''}
          >
            INFORMATIVO
          </button>
          <button
            type="button"
            onClick={() => {
              setFiltroStatus('BLOQ');
              handleFiltrosStatus('BLOQ');
            }}
            className={filtroStatus === 'BLOQ' ? 'botao-de-filtro-ativo' : ''}
          >
            IMPEDITIVO
          </button>
        </div>
      </div>
    </div>
  );
};
