import React, { Fragment } from 'react';
import { MdInfo, MdOutlineWarning } from 'react-icons/md';
import { LineBreak, TooltipText, TooltipWrapper, IconWrapper } from './styles';

interface TooltipStatusProps {
  tipo: 'INF' | 'BLOQ';
  mensagem: string;
}

const messages = (
  mensagem: string,
  color: string,
  index: number,
): JSX.Element[] => {
  const messageLines = mensagem.split('\n').map((line, idx) => {
    const title = line.trim() !== '' && /^(inf|imp)/i.test(line.trim());

    return (
      <Fragment key={line}>
        {title && (
          <p
            style={{
              borderBottom: `0.0938rem solid ${color}`,
              fontWeight: 'bold',
            }}
          >
            {line}
          </p>
        )}
        {!title && <span>{line}</span>}
        {idx !== mensagem.split('\n').length - 1 && <LineBreak />}
      </Fragment>
    );
  });

  return messageLines.map((line, idx) =>
    React.cloneElement(line, { key: `${line.key}-${index + idx}` }),
  );
};

export const TooltipStatusValidacao: React.FC<TooltipStatusProps> = ({
  tipo,
  mensagem,
}) => {
  const items = {
    INF: {
      icon: MdInfo,
      color: '#ffbf00',
    },
    BLOQ: {
      icon: MdOutlineWarning,
      color: '#e83a3a',
    },
  };

  const { color } = items[tipo];
  const Icon = items[tipo].icon;

  return (
    <TooltipWrapper>
      <IconWrapper>
        <Icon color={color} />
      </IconWrapper>
      <TooltipText color={color}>
        {messages(mensagem, color, Math.floor(Math.random() * 1000))}
      </TooltipText>
    </TooltipWrapper>
  );
};
