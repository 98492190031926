import React from 'react';
import { Status } from './Status';
import { Filtros } from './Filtros';
import { Acoes } from './Acoes';
import { Container } from './styles';

export const Home: React.FC = () => {
  return (
    <Container>
      <Filtros />
      <Status />
      <Acoes />
    </Container>
  );
};
