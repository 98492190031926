import React, { useCallback, useState } from 'react';
import Separator from '~/components/Separator';
import { useModalDeCorrecoes } from '../../../ModalDeCorrecoesContext';
import { IoReload } from 'react-icons/io5';
import { CgArrowsExchangeAlt } from 'react-icons/cg';

import { useValidadorXML } from '~/pages/ValidadorXML/ValidadorContext';
import { revalidaItem } from '~/pages/ValidadorXML/Funcoes/Correcao';

import api from '~/services/api';
import { toast } from 'react-toastify';
import { CardCorrecao } from '../../../Components';

/**
 * @param Acoes Aqui estão ações que podem ser tomadas para o item independente de haver alguma critica (inconformidade)
 */

export const Acoes: React.FC = () => {
  const [executandoAcao, setExecutandoAcao] = useState<string>('');
  const {
    dadosItemACorrigir,
    listaDeStatus,
    setModalTab,
    setListaDeNCM,
    setFuncaoConfirmar,
    handleAtualizaUnCompraEReferencia,
    handleResponseData,
  } = useModalDeCorrecoes();

  const { dadosDaCapa, dadosErpDoFornecedor, parametrosValidacao } =
    useValidadorXML();

  const listaNCM = useCallback(
    async (filtro: string) => {
      const { data } = await api.get(`/busca-ncm`, {
        params: {
          filtro,
          des_sigla: dadosItemACorrigir.lojaUF,
          cod_loja: dadosItemACorrigir.cod_loja,
        },
      });
      if (data.message && data.message === 'Success') {
        setListaDeNCM(data.data);
      }
    },
    [dadosItemACorrigir.cod_loja, dadosItemACorrigir.lojaUF, setListaDeNCM],
  );

  const handleRevalidarItem = useCallback(async () => {
    setExecutandoAcao('revalidar_item');
    const response = await revalidaItem({
      dadosItemACorrigir,
      listaDeStatus,
      parametrosValidacao,
      dadosDaCapa,
      dadosErpDoFornecedor,
    });
    await handleResponseData({
      response,
      campo: 'nenhum',
      origem: 'nenhum',
    });
    setExecutandoAcao('');
  }, [
    dadosDaCapa,
    dadosErpDoFornecedor,
    dadosItemACorrigir,
    handleResponseData,
    listaDeStatus,
    parametrosValidacao,
  ]);

  const handleUnCompraEReferencia = useCallback(async () => {
    setExecutandoAcao('atualiza_un_ref');
    await handleAtualizaUnCompraEReferencia();
    setExecutandoAcao('');
  }, [handleAtualizaUnCompraEReferencia]);

  return (
    <div className="row flex-column">
      <div className="col-12">
        <Separator labelText="Ações" sidePadding="0px" />
      </div>
      {/* (RE)VALIDAR PRODUTO */}
      <CardCorrecao
        Icon={IoReload}
        titulo="(RE)VALIDAR PRODUTO"
        descricao="Revalide o produto. Essa opção deve ser utilizada quando algum
          conflito foi corrigido por outra tela no ERP."
        onClick={() => handleRevalidarItem()}
        executando={executandoAcao === 'revalidar_item'}
      />
      {/* SUSBTITUIR PRODUTO */}
      <CardCorrecao
        Icon={CgArrowsExchangeAlt}
        titulo="SUBSTITUIR PRODUTO"
        descricao="Substitua o produto selecionado por outro existente em seu
              cadastro."
        onClick={() => {
          setFuncaoConfirmar('substituirProduto');
          setModalTab('substituirProduto');
        }}
      />
      {/* ALTERAR NCM */}
      <CardCorrecao
        Icon={IoReload}
        titulo="Alterar NCM"
        descricao="Altere o NCM selecionado por outro existente no ERP."
        onClick={async () => {
          if (
            dadosItemACorrigir.cod_gtin_principal === '' ||
            dadosItemACorrigir.cod_produto === ''
          ) {
            toast.warning(
              'Para Alterar o NCM é Necessário Selecionar um Produto!',
            );
            return;
          }
          await listaNCM(dadosItemACorrigir.NCM);
          setFuncaoConfirmar('alterarNCM');
          setModalTab('alterarNCM');
        }}
      />
      {/* ALTERAR CFOP */}
      <CardCorrecao
        Icon={IoReload}
        titulo="Alterar CFOP"
        descricao="Altere o CFOP selecionado por outro existente no ERP."
        onClick={async () => {
          if (
            dadosItemACorrigir.cod_gtin_principal === '' ||
            dadosItemACorrigir.cod_produto === ''
          ) {
            toast.warning(
              'Para Alterar o CFOP é Necessário Selecionar um Produto!',
            );
            return;
          }
          setFuncaoConfirmar('alterarCFOP');
          setModalTab('alterarCFOP');
        }}
      />
      {/* ATUALIZA UN E REF */}
      <CardCorrecao
        Icon={IoReload}
        titulo="Atualizar Unidade de Compra e Referência"
        descricao=" Atualizar o cadastro de produto com informações do XML."
        onClick={async () => handleUnCompraEReferencia()}
        executando={executandoAcao === 'atualiza_un_ref'}
      />
    </div>
  );
};
