import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  z-index: 10;

  &:hover span {
    visibility: visible;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const TooltipText = styled.span<{ color: string }>`
  z-index: 15;
  visibility: hidden;
  min-width: 17.5rem;
  max-width: 21.875rem;
  background-color: #555555f6;

  border: solid 0.0938rem ${(props) => props.color};
  color: #fff;
  text-align: start;
  border-radius: 0.375rem;
  padding: 0.3125rem;
  position: absolute;
  top: 125%;
  left: 50%;
  margin-left: 2.2rem;
  opacity: 0;
  transition: opacity 0.3s;

  ${TooltipWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

export const LineBreak = styled.div`
  margin-top: 0.3125rem;
`;
