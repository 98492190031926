import styled from 'styled-components';

export const Container = styled.div`
  /**
    Sessão Filtros
  */
  .botoes-de-filtro {
    button {
      padding: 0 10px;
      height: 24px;
      background: #c6c6c6;
      border: none;

      cursor: pointer;
      & + button {
        margin-left: 2px;
      }
    }
  }
  .botao-de-filtro-ativo {
    background: ${(props) => props.theme.colors.nav} !important;
    color: #fff;
  }
  /**
    Sessão Status
  */
  .status-session {
    height: 230px;
    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #c9c9c9;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #8850bf;

      transition: 0.2s;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #c790f5;
    }
  }
`;
