import React, { useCallback, useEffect } from 'react';
import Separator from '~/components/Separator';
import { useModalDeCorrecoes } from '../../../ModalDeCorrecoesContext';
import { Link } from 'react-router-dom';
import { IoCalculatorOutline } from 'react-icons/io5';
import { BsBasket, BsPencilSquare } from 'react-icons/bs';
import { AiOutlineBarcode, AiOutlineRight } from 'react-icons/ai';

import CircularProgress from '@material-ui/core/CircularProgress';
import { useValidadorXML } from '~/pages/ValidadorXML/ValidadorContext';
import {
  retornarALinha,
  retornarAoMix,
} from '~/pages/ValidadorXML/Funcoes/Correcao';

import Swal from 'sweetalert2';
import api from '~/services/api';
import { toast } from 'react-toastify';

/**
 * @param Status Aqui são renderizadas todas as criticas (inconformidades) anotadas ao validar os itens do XML.
 */

export const Status: React.FC = () => {
  const {
    setDadosItemACorrigir,
    dadosItemACorrigir,
    setListaDeStatus,
    listaDeStatus,
    executando,
    setExecutando,
    setListaDeGtins,
    setModalTab,
    setFuncaoConfirmar,
    setCodigoDeBarra,
    setFiltroOrigem,
    setFiltroStatus,
    handleAtualizaUnCompraEReferencia,
  } = useModalDeCorrecoes();

  const {
    dadosDaCapa,
    dadosErpDoFornecedor,
    itemACorrigir,
    parametrosValidacao,
    returnDadosRevalidados,
    setCamposAlterados,
    setShowModalDeCorrecoes,
    showModalDeCorrecoes,
  } = useValidadorXML();

  useEffect(() => {
    if (!showModalDeCorrecoes) setListaDeStatus([]);
  }, [setListaDeStatus, showModalDeCorrecoes]);

  const handleRetornarAoMix = useCallback(async () => {
    const revalidado = await retornarAoMix({
      dadosItemACorrigir,
      parametrosValidacao,
      dadosDaCapa,
      dadosErpDoFornecedor,
    });
    // retorna dados revalidador
    setDadosItemACorrigir(revalidado);
    const codStatus = dadosItemACorrigir.statusDaValidacao.map((status) => {
      return status.codStatus;
    });
    setListaDeStatus(codStatus);
    setExecutando({ ...executando, 2: false });
    setCamposAlterados((prev) => [
      ...prev,
      {
        index: itemACorrigir.index,
        campo: 'flg_inativo',
        origem: 'Produto inserido no mix.',
      },
    ]);
    returnDadosRevalidados(revalidado);
  }, [
    dadosDaCapa,
    dadosErpDoFornecedor,
    dadosItemACorrigir,
    executando,
    itemACorrigir.index,
    parametrosValidacao,
    returnDadosRevalidados,
    setCamposAlterados,
    setDadosItemACorrigir,
    setExecutando,
    setListaDeStatus,
  ]);

  const handleRetornarALinha = useCallback(async () => {
    const revalidado = await retornarALinha({
      dadosItemACorrigir,
      parametrosValidacao,
      dadosDaCapa,
      dadosErpDoFornecedor,
    });
    // retorna dados revalidador
    setDadosItemACorrigir(revalidado);
    const codStatus = dadosItemACorrigir.statusDaValidacao.map((status) => {
      return status.codStatus;
    });
    setListaDeStatus(codStatus);
    setExecutando({ ...executando, 3: false });
    setCamposAlterados((prev) => [
      ...prev,
      {
        index: itemACorrigir.index,
        campo: 'flg_fora_linha',
        origem: 'Produto retornado à linha.',
      },
    ]);
    returnDadosRevalidados(revalidado);
  }, [
    dadosDaCapa,
    dadosErpDoFornecedor,
    dadosItemACorrigir,
    executando,
    itemACorrigir.index,
    parametrosValidacao,
    returnDadosRevalidados,
    setCamposAlterados,
    setDadosItemACorrigir,
    setExecutando,
    setListaDeStatus,
  ]);

  const listaGtinsPorPlu = useCallback(
    async (cod_produto: string) => {
      const { data } = await api.post('/busca-gtins-por-plu', {
        cod_produto,
      });
      if (data.message && data.message === 'Success') {
        setListaDeGtins(data.data);
      }
    },
    [setListaDeGtins],
  );

  const renderTab = useCallback(() => {
    if (dadosItemACorrigir.statusDaValidacao) {
      const tabsToRender: any = [];
      listaDeStatus.forEach((element) => {
        switch (element) {
          case 1:
            tabsToRender.push(
              <Link
                to="/produto"
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                style={{
                  color: 'inherit',
                  textDecoration: 'inherit',
                }}
                key={1}
                onClick={() => {
                  setFiltroOrigem('TODOS');
                  setFiltroStatus('TODOS');
                  setModalTab('filtros');
                  setCodigoDeBarra('');
                  setShowModalDeCorrecoes(false);
                }}
              >
                <div>
                  <BsBasket size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Cadastrar Produto</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">Efetue o cadastro do produto.</small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </Link>,
            );
            break;
          case 2:
            tabsToRender.push(
              <div
                className={`col-12 d-flex flex-row align-items-center modal-acoes ${
                  executando[2] === true ? 'executando' : ''
                }`}
                onClick={() => {
                  setExecutando({ ...executando, 2: true });
                  if (executando[2] === false) handleRetornarAoMix();
                }}
                key={2}
              >
                <div>
                  {executando[2] === false ? (
                    <BsBasket size="30px" />
                  ) : (
                    <CircularProgress size={27} style={{ color: '#ffffff' }} />
                  )}
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Adicionar Produto no Mix</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Adicione o produto ao mix da loja.
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 3:
            tabsToRender.push(
              <div
                className={`col-12 d-flex flex-row align-items-center modal-acoes ${
                  executando[3] === true ? 'executando' : ''
                }`}
                onClick={() => {
                  setExecutando({ ...executando, 3: true });
                  if (executando[3] === false) handleRetornarALinha();
                }}
                key={3}
              >
                <div>
                  {executando[3] === false ? (
                    <BsBasket size="30px" />
                  ) : (
                    <CircularProgress size={27} style={{ color: '#ffffff' }} />
                  )}
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0 title-teste">Retornar em Linha</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">Retorne o produto a linha.</small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 100:
            tabsToRender.push(
              <div
                className={`col-12 d-flex flex-row align-items-center modal-acoes ${
                  executando[100] === true ? 'executando' : ''
                }`}
                onClick={() => {
                  setExecutando({ ...executando, 100: true });
                  if (executando[100] === false)
                    handleAtualizaUnCompraEReferencia();
                }}
                key={100}
              >
                <div>
                  {executando[100] === false ? (
                    <BsPencilSquare size="30px" />
                  ) : (
                    <CircularProgress size={27} style={{ color: '#ffffff' }} />
                  )}
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">
                      Atualizar Unidade de Compra e Referência
                    </h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Atualizar o cadastro de produto com informações do XML.
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 101:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                key={101}
              >
                <div>
                  <BsPencilSquare size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Atualizar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">Produto sem NCM informado.</small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 102:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                key={102}
              >
                <div>
                  <BsPencilSquare size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Produto não é Mercadoria</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Não é possível dar entrada em Produto do tipo
                      composição/produção.
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 103:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                key={103}
              >
                <div>
                  <BsPencilSquare size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Unidade difere de KG</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Produto pesável, precisa ser unidade KG.
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 200:
            tabsToRender.push(
              <div
                className={`col-12 d-flex flex-row align-items-center modal-acoes ${
                  executando[200] === true ? 'executando' : ''
                }`}
                onClick={async () => {
                  Swal.fire(
                    'Produto com GTIN-14 deve estar associado à um produto.',
                    'Vá até o cadastro de produto, regularize a situação e clique em "(Re)validar produto".',
                    'warning',
                  );
                }}
                key={200}
              >
                <div>
                  {executando[200] === false ? (
                    <AiOutlineBarcode size="30px" />
                  ) : (
                    <CircularProgress size={27} style={{ color: '#ffffff' }} />
                  )}
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Atualizar Código de Barras</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Produto com GTIN-14 deve estar associado à um produto.
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 201:
            tabsToRender.push(
              <div
                className={`col-12 d-flex flex-row align-items-center modal-acoes ${
                  executando[201] === true ? 'executando' : ''
                }`}
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_produto === '' ||
                    dadosItemACorrigir.cod_gtin_principal === ''
                  ) {
                    toast.warn(
                      'Para Atualizar o Código de Barras é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  await listaGtinsPorPlu(dadosItemACorrigir.cod_produto);
                  setCodigoDeBarra(dadosItemACorrigir.cEAN);
                  if (executando[201] === false)
                    setModalTab('atualizarCodigoDeBarras');
                }}
                key={201}
              >
                <div>
                  {executando[201] === false ? (
                    <AiOutlineBarcode size="30px" />
                  ) : (
                    <CircularProgress size={27} style={{ color: '#ffffff' }} />
                  )}
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Atualizar Código de Barras</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      {
                        dadosItemACorrigir.statusDaValidacao.find(
                          (item) => item.codStatus === 201,
                        )?.messageStatus
                      }
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 202:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                key={202}
                onClick={() => {
                  setFuncaoConfirmar('substituirProduto');
                  setModalTab('substituirProduto');
                }}
              >
                <div>
                  <AiOutlineBarcode size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Substituir Produto</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Produto Associado. Entrada somente no Produto-Pai.
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 300:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={300}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      {
                        dadosItemACorrigir.statusDaValidacao.find(
                          (item) => item.codStatus === 300,
                        )?.messageStatus
                      }
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 301:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={301}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Tributação do XML não está vinculada ao NCM do Produto.
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 302:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={302}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      {
                        dadosItemACorrigir.statusDaValidacao.find(
                          (item) => item.codStatus === 302,
                        )?.messageStatus
                      }
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 303:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={303}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Tributação do XML (Substituição) difere do cadastro
                      (Tributado).
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 304:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={304}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Tributação do XML não encontrada.
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 305:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={305}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Tributação do estado do fornecedor não informada no
                      cadastro do NCM.
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 306:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={306}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">
                      Nenhuma tributação do mesmo tipo encontrada.
                    </small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 307:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={307}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">NCM divergente.</small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 308:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={308}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">CEST divergente.</small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 309:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={309}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">%IVA divergente.</small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 310:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  setFuncaoConfirmar('alterarCFOP');
                  setModalTab('alterarCFOP');
                }}
                key={310}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar CFOP</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">CFOP não informado.</small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          case 311:
            tabsToRender.push(
              <div
                className="col-12 d-flex flex-row align-items-center modal-acoes"
                onClick={async () => {
                  if (
                    dadosItemACorrigir.cod_gtin_principal === '' ||
                    dadosItemACorrigir.cod_produto === ''
                  ) {
                    toast.warning(
                      'Para Alterar o NCM é Necessário Selecionar um Produto!',
                    );
                    return;
                  }
                  setFuncaoConfirmar('alterarNCM');
                  setModalTab('alterarNCM');
                }}
                key={311}
              >
                <div>
                  <IoCalculatorOutline size="30px" />
                </div>
                <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
                  <div className="d-flex align-items-end">
                    <h5 className="m-0">Alterar NCM</h5>
                  </div>
                  <div className="d-flex align-items-start">
                    <small className="m-0">NCM divergente.</small>
                  </div>
                </div>
                <div>
                  <AiOutlineRight size="20px" />
                </div>
              </div>,
            );
            break;
          default:
            break;
        }
      });
      return tabsToRender;
    }
  }, [
    dadosItemACorrigir.statusDaValidacao,
    dadosItemACorrigir.cod_produto,
    dadosItemACorrigir.cod_gtin_principal,
    dadosItemACorrigir.cEAN,
    listaDeStatus,
    executando,
    setFiltroOrigem,
    setFiltroStatus,
    setModalTab,
    setCodigoDeBarra,
    setShowModalDeCorrecoes,
    setExecutando,
    handleRetornarAoMix,
    handleRetornarALinha,
    handleAtualizaUnCompraEReferencia,
    listaGtinsPorPlu,
    setFuncaoConfirmar,
  ]);

  return (
    <div className="row flex-column">
      <div className="col-12">
        <Separator
          labelText={`Status (${listaDeStatus.length})`}
          sidePadding="0px"
        />
      </div>
      {/*
        Aqui serão renderizadas todas as criticas
      */}
      {dadosItemACorrigir.statusDaValidacao && (
        <div className="status-session">
          {renderTab().map((tab: any) => {
            return tab;
          })}
        </div>
      )}
    </div>
  );
};
